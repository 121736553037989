import React, { useState, useEffect, useMemo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Space, Table, Input, Button, Tooltip, Badge, Collapse } from "antd";
import {
  CloseOutlined,
  FieldTimeOutlined,
  DesktopOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { SocketContext } from "../../context/socketContext";
import ReactCountryFlag from "react-country-flag";
import { Get_Shortcut } from "../../redux/Shortcuts/ShortcutsActions";
import { incomingMessage } from "../../helpers/sounds";
import { Get_agents_by_brand } from "../../redux/Users/UsersActions";
import { countries } from "../../helpers/countries";
import mobileImg from "../../assets/image/mobile.png";
import tabletImg from "../../assets/image/tablet.png";
import macImg from "../../assets/image/mac.png";
import linuxImg from "../../assets/image/linux.png";
import windowsImg from "../../assets/image/windows.png";
import edgeImg from "../../assets/image/edge.png";
import firefoxImg from "../../assets/image/firefox.png";
import safariImg from "../../assets/image/safari.png";
import chromeImg from "../../assets/image/chrome.png";
import operaImg from "../../assets/image/opera.png";
import unknownImg from "../../assets/image/unknown.png";
import UnixImg from "../../assets/image/unix.png";
import { onLeaveServed } from "../../redux/VisitorsJoined/VisitorsJoinedActions";
// import Draggable from 'react-draggable';
import CountdownTimer from "../../components/CountdownTimer/CountdownTimer";

const Visitors = () => {
  const dispatch = useDispatch();
  const [time, setTime] = useState(new Date());
  const [arrow, setArrow] = useState("Show");
  const { setSelectedVisitorData, Socket, VisitorData, messageId } =
    useContext(SocketContext);
  const servedData = useSelector((state) => state.VisitorReducer.data);
  const userData = useSelector((state) => state.Auth.userData);
  let filterBrands = userData?.assignBrands?.map((i) => i?.token);
  const usertoken = useSelector((state) => state.Auth.token);
  const [acceptedData, setacceptedData] = useState(null);
  const [leaveID, setleaveID] = useState(null);
  /// IdealVisitors filter
  const IdealVisitors = VisitorData?.filter(
    (i) => i?.data?.isIdealState == true
  );
  /// ActiveVisitors filter
  const ActiveVisitors = VisitorData?.filter(
    (i) => i?.data?.isIdealState == false && i?.servedBy?.length == 0
  );
  const ServedVisitors = VisitorData?.filter(
    (i) => i?.data?.isIdealState == false && i?.servedBy?.length > 0
  );
  /// getting names
  useEffect(() => {
    if (!Socket) return;
    Socket.on(`accpetedVisitor`, (data) => {
      setacceptedData(data);
    });
    Socket.on(`visitorLeavetoAgent`, (data) => {
      setleaveID(data);
    });
  }, [Socket]);
  useEffect(() => {
    if (leaveID !== null) {
      dispatch(onLeaveServed(leaveID, servedData));
    }
    setleaveID(null);
  }, [leaveID]);

  useEffect(() => {
    if (acceptedData !== null) {
      let FindUser = VisitorData?.find(
        (i) => i?.unique_id == acceptedData?.unique_id
      );
      setSelectedVisitorData(FindUser);
      setacceptedData(null);
    }
  }, [acceptedData]);

  useEffect(() => {
    let data = {
      brandToken: filterBrands,
      user_id: userData?._id,
    };
    dispatch(Get_agents_by_brand(data, usertoken));
  }, []);

  /// end

  useEffect(() => {
    let data = {
      brandToken: filterBrands,
      _id: userData?._id,
    };
    dispatch(Get_Shortcut(data, usertoken));
  }, []);

  // timeout
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  });

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }
    if (arrow === "Show") {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const columns = [
    {
      title: "Visitor",
      dataIndex: "unique_id",
      width: 120,
      render: (text, item) => <div className="visitorIcon">{text}</div>,
    },
    {
      title: "Name",
      width: 120,
      dataIndex: "name",
      // width: 100,
    },
    // {
    //   title: "Country",
    //   // width: '6%',
    //   dataIndex: "location",
    //   render: (item) => {
    //     let findcountry = countries?.find((i) => i?.cca2 == item?.country);
    //     return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 47 }}>
    //       <Tooltip
    //         placement="bottom"
    //         title={item?.city + item?.region + findcountry?.name}
    //       >
    //         <ReactCountryFlag
    //           countryCode={item?.country}
    //           style={{
    //             fontSize: '2em',
    //           }}
    //           svg
    //         />
    //         {/* <p>{item?.city && `${item?.city},`} {item?.region && `${item?.city},`} {findcountry?.name}</p> */}
    //       </Tooltip>
    //     </div>
    //   }
    // },

    // {
    //   title: "Device",
    //   dataIndex: "data",
    //   // width: '5%',
    //   render: (item) => {
    //     return <div>
    //       <div style={{ display: "flex", justifyContent: "center", alignContent: 'center', width: 47 }}>
    //         <Tooltip
    //           placement="bottom"
    //           title={item?.isMobileDevice ? "Mobile" : item?.isTabletdDevice ? "Tablet" : item?.platform}
    //           arrow={mergedArrow}>
    //           {item?.isMobileDevice ?
    //             <img style={{ height: 35, width: 35 }} src={mobileImg} alt="mobile" /> :
    //             item?.isTabletdDevice ?
    //               <img style={{ height: 35, width: 35 }} src={tabletImg} alt="tabletImg" />
    //               : item?.platform == "MacOS" ?
    //                 <img style={{ height: 35, width: 35 }} src={macImg} alt="macImg" />
    //                 : item?.platform == "Windows" ?
    //                   <img style={{ height: 35, width: 35 }} src={windowsImg} alt="windowsImg" /> :
    //                   item?.platform == "Linux" ? <img style={{ height: 35, width: 35 }} src={linuxImg} alt="linuxImg" /> : <DesktopOutlined className="ip" />}

    //         </Tooltip>
    //       </div>
    //     </div>
    //   }
    // },
    // {
    //   title: "Browser",
    //   dataIndex: "data",
    //   // width: '6%',
    //   render: (item) => {
    //     return <div>
    //       <div style={{ display: "flex", justifyContent: "center", alignContent: 'center', width: 47 }}>
    //         <Tooltip
    //           placement="bottom"
    //           title={item?.browser}
    //           arrow={mergedArrow}>
    //           {item?.browser == "Chrome" ?
    //             <img style={{ height: 20, width: 20 }} src={chromeImg} alt="chromeImg" /> :
    //             item?.browser == "Edge" ?
    //               <img style={{ height: 20, width: 20 }} src={edgeImg} alt="edgeImg" /> :
    //               item?.browser == "Safari" ?
    //                 <img style={{ height: 20, width: 20 }} src={safariImg} alt="safariImg" /> :
    //                 item?.browser == "Opera" ?
    //                   <img style={{ height: 20, width: 20 }} src={operaImg} alt="operaImg" /> :
    //                   item?.browser == "Firefox" ?
    //                     <img style={{ height: 20, width: 20 }} src={firefoxImg} alt="firefoxImg" /> :
    //                     <img style={{ height: 20, width: 20 }} src={unknownImg} alt="unknownImg" />
    //           }
    //           {/* <FieldTimeOutlined className="time" /> */}
    //         </Tooltip>
    //       </div>
    //     </div>
    //   }
    // },
    {
      title: "Details",
      width: 150,
      dataIndex: "location",
      render: (location, record) => {
        const { data } = record;

        const findcountry = countries?.find(
          (i) => i?.cca2 === location?.country
        );
        return (
          <div
            style={{
              display: "flex",
              // justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div style={{ marginTop: "-6px", marginRight: "10px" }}>
              <Tooltip
                placement="bottom"
                title={`${location?.city} ${location?.region} ${findcountry?.name}`}
              >
                <ReactCountryFlag
                  countryCode={location?.country}
                  style={{ fontSize: "20px" }}
                  svg
                />
              </Tooltip>
            </div>
            <div style={{ marginRight: "10px" }}>
              <Tooltip
                placement="bottom"
                title={
                  data?.isMobileDevice
                    ? "Mobile"
                    : data?.isTabletdDevice
                    ? "Tablet"
                    : data?.platform
                }
                arrow={mergedArrow}
              >
                {data?.isMobileDevice ? (
                  <img
                    style={{ height: 30, width: 30 }}
                    src={mobileImg}
                    alt="mobile"
                  />
                ) : data?.isTabletdDevice ? (
                  <img
                    style={{ height: 30, width: 30 }}
                    src={tabletImg}
                    alt="tabletImg"
                  />
                ) : data?.platform === "MacOS" ? (
                  <img
                    style={{ height: 30, width: 30 }}
                    src={macImg}
                    alt="macImg"
                  />
                ) : data?.platform === "Windows" ? (
                  <img
                    style={{ height: 30, width: 30 }}
                    src={windowsImg}
                    alt="windowsImg"
                  />
                ) : data?.platform === "Linux" ? (
                  <img
                    style={{ height: 30, width: 30 }}
                    src={linuxImg}
                    alt="linuxImg"
                  />
                ) : data?.platform === "Unix" ? (
                  <img
                    style={{ height: 30, width: 30, objectFit: "contain" }}
                    src={UnixImg}
                    alt="UnixImg"
                  />
                ) : (
                  <DesktopOutlined className="ip" />
                )}
              </Tooltip>
            </div>
            <div style={{ marginRight: "10px" }}>
              <Tooltip
                placement="bottom"
                title={data?.browser}
                arrow={mergedArrow}
              >
                {data?.browser === "Chrome" ? (
                  <img
                    style={{ height: 20, width: 20 }}
                    src={chromeImg}
                    alt="chromeImg"
                  />
                ) : data?.browser === "Edge" ? (
                  <img
                    style={{ height: 20, width: 20 }}
                    src={edgeImg}
                    alt="edgeImg"
                  />
                ) : data?.browser === "Safari" ? (
                  <img
                    style={{ height: 20, width: 20 }}
                    src={safariImg}
                    alt="safariImg"
                  />
                ) : data?.browser === "Opera" ? (
                  <img
                    style={{ height: 20, width: 20 }}
                    src={operaImg}
                    alt="operaImg"
                  />
                ) : data?.browser === "Firefox" ? (
                  <img
                    style={{ height: 20, width: 20 }}
                    src={firefoxImg}
                    alt="firefoxImg"
                  />
                ) : (
                  <img
                    style={{ height: 20, width: 20 }}
                    src={unknownImg}
                    alt="unknownImg"
                  />
                )}
              </Tooltip>
            </div>
          </div>
        );
      },
    },

    {
      title: "Served by",
      dataIndex: "servedBy",
      width: 150,
      render: (text) => {
        return text
          ? text?.map((i, index) =>
              text?.length - 1 == index ? i?.name : i?.name + ", "
            )
          : "";
      },
    },
    {
      title: "Meta Title",
      align: "left",
      dataIndex: "data",
      width: 180,
      render: (text) => {
        let page =
          text?.pageTitle?.length > 30
            ? text?.pageTitle?.substring(0, 30 / 2) + "..."
            : text?.pageTitle;
        return (
          <Tooltip
            placement="bottom"
            title={page !== "null" ? text?.pageTitle : "---"}
          >
            <p style={{ textAlign: "left" }}>{page !== "null" ? page : "-"}</p>{" "}
          </Tooltip>
        );
      },
    },
    {
      title: "Viewing",
      align: "left",
      dataIndex: "data",
      width: 300,
      render: (text) => {
        let page =
          text?.url.url?.length > 40
            ? text?.url?.url?.substring(0, 40) + "..."
            : text?.url?.url;
        return (
          <Tooltip
            placement="bottom"
            // overlayInnerStyle={{
            //   whiteSpace: "nowrap",
            //   minWidth: "fit-content",
            // }}
            title={<span>{text?.url?.url}</span>}
          >
            <a
              href={text?.url?.url}
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              style={{ textAlign: "left" }}
            >
              {page}
            </a>
          </Tooltip>
        );
      },
    },

    {
      title: "IP Address",
      dataIndex: "ip",
      width: 110,
      render: (text) => {
        return text ? text : "-";
      },
    },
    {
      title: "Online",
      align: "center",
      dataIndex: "start_time",
      width: 90,
      render: (text) => {
        // return <p style={{ textAlign: "center" }}>{moment(text).fromNow(true)}</p>;
        return <CountdownTimer start_time={text} />;
      },
    },
    {
      title: "Visits",
      align: "center",
      dataIndex: "visits",
      width: 70,
      render: (text) => {
        return text ? <p className="badge">{text} </p> : "-";
      },
    },
    {
      title: "Chats",
      align: "center",
      dataIndex: "chats",
      width: 70,
      render: (text) => {
        return text ? <p className="chat">{text}</p> : "-";
      },
    },
    ...(messageId?.length > 0
      ? [
          {
            title: "Msgs",
            dataIndex: "conversationId",
            width: "6%",
            visible: false,
            render: (text) => {
              let checkID = messageId?.filter((i) => i == text);
              return (
                <div className="msg pulsatese">
                  {checkID?.length > 0 ? checkID?.length : 0}
                </div>
              );
            },
          },
        ]
      : []),
  ];

  const onClickRow = (data) => {
    setSelectedVisitorData(data);
    // let findiD = messageId?.filter((i) => i != data?.conversationId);
    // setmessageId(findiD);
  };
  return (
    <div
      className="modal-containers"
      id={"modal-container"}
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      {/* <div className="topBtns">
        <div style={{ display: "none" }}>{moment(time).fromNow(true)}</div>
      </div> */}
      {ServedVisitors?.length > 0 && (
        <div style={{ position: "relative" }}>
          {/* <h6>Served Visitor</h6> */}
          <Collapse
            size="small"
            defaultActiveKey={["1"]}
            items={[
              {
                key: "1",
                label: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <b className="activeHeading">
                      Served ({ServedVisitors?.length})
                    </b>
                  </div>
                ),
                children: (
                  <Table
                    onRow={(data, rowIndex) => {
                      return {
                        onClick: (event) => onClickRow(data),
                      };
                    }}
                    size="small"
                    columns={columns}
                    dataSource={ServedVisitors?.map((item, index) => ({
                      ...item,
                      key: index,
                    }))}
                    pagination={false}
                    className="tblServed"
                  />
                ),
              },
            ]}
          />
        </div>
      )}
      <div style={{ marginTop: 10 }}>
        {/* <h6>Active Visitor</h6> */}
        <Collapse
          size="small"
          defaultActiveKey={["1"]}
          items={[
            {
              key: "1",
              label: (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <b className="activeHeading">
                    Active ({ActiveVisitors?.length})
                  </b>
                </div>
              ),
              children: (
                <Table
                  onRow={(data, rowIndex) => {
                    return {
                      onClick: (event) => onClickRow(data),
                    };
                  }}
                  size="small"
                  columns={columns}
                  dataSource={ActiveVisitors?.map((item, index) => ({
                    ...item,
                    key: index,
                  }))}
                  pagination={false}
                  className="tblServed"
                />
              ),
            },
          ]}
        />
      </div>

      {IdealVisitors?.length > 0 && (
        <div style={{ position: "relative", marginTop: 10 }}>
          {/* <h6>Idle Visitor</h6> */}

          <Collapse
            size="small"
            defaultActiveKey={["1"]}
            items={[
              {
                key: "1",
                label: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <b className="activeHeading">
                      Idle ({IdealVisitors?.length})
                    </b>
                  </div>
                ),

                children: (
                  <Table
                    onRow={(data, rowIndex) => {
                      return {
                        onClick: (event) => onClickRow(data),
                      };
                    }}
                    size="small"
                    columns={columns}
                    dataSource={IdealVisitors?.map((item, index) => ({
                      ...item,
                      key: index,
                    }))}
                    pagination={false}
                    className="tblServed"
                  />
                ),
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default Visitors;
