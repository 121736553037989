import React, { useState, useEffect, useContext } from "react";
import { Layout, theme, Alert, Button, notification, Space, Badge, Select } from "antd";
import { Avatar } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
// import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "antd";
import * as actionTypes from "../../redux/Auth/AuthTypes";
import { SocketContext } from "../../context/socketContext";
import { getTickets } from "../../redux/Tickets/TicketsActions";
import { getProfile } from "../../redux/Profile/ProfileActions";
import { visitorSounds } from "../../helpers/sounds";
// import FloatButtons from "../floatbuttons/FloatButtons";
// import Marquee from 'react-fast-marquee';
import { getVisitorAllHistory } from "../../redux/AllHistory/AllHistoryActions";
import { useNavigate, useLocation } from "react-router-dom";
import { GetOverAllUsers } from "../../redux/Users/UsersActions";
import Clock from "../clock";

const Headers = () => {
  const userData = useSelector((state) => state.Auth.userData);
  const usertoken = useSelector((state) => state.Auth.token);
  const [newVisitor, setnewVisitor] = useState(null);
  const [IsActive, setIsActive] = useState("22");
  const [api, contextHolder] = notification.useNotification();
  const { allActive, setallActive, Socket, VisitorData, setVisitorData, SelectedVisitorData, setSelectedVisitorData, connected, setdisconnectId, disconnectId } = useContext(SocketContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [InviteData, setInviteData] = useState(null);
  const [recivedTrasferdata, setrecivedTrasferdata] = useState([]);
  const [blockeddata, setblockeddata] = useState(null);

  useEffect(() => {
    dispatch(GetOverAllUsers(usertoken))
  }, []);

  useEffect(() => {
    if (InviteData === null) return;
    if (recivedTrasferdata?.length > 0) {
      let isInArrr = recivedTrasferdata?.some((i) => i?.agentData?.name == userData?.name
        && i?.visitorData?.unique_id == InviteData?.unique_id);
      if (isInArrr) return;
      else {
        setrecivedTrasferdata((pre) => [InviteData, ...pre]);
        notificationmsg(InviteData);
      }
    }
    else {
      setrecivedTrasferdata([InviteData]);
      notificationmsg(InviteData);
    }
    setInviteData(null);
  }, [InviteData]);

  const notificationmsg = (i) => {
    const btn = (x) => (
      <Space>
        <Button onClick={() => onRejct(x)}>
          Reject
        </Button>
        <Button type="primary" onClick={() => onAccept(x)}>
          Confirm
        </Button>
      </Space>
    );
    const des = (i) => (

      <>
        <p>You Have Recived a Chat From <span style={{ fontWeight: "bold" }}>{i?.senderData?.name}</span></p>
        <p>Visitor : <span style={{ fontWeight: "bold" }}>
          {i?.visitorData?.name !== "" ?
            i?.visitorData?.name : `#[${i?.visitorData?.unique_id}]`}</span></p>
      </>
    );
    notification.info({
      message: <p style={{ fontWeight: "bold" }}>Chat Request</p>,
      description: des(i),
      btn: btn(i),
      closeIcon: false,
      duration: null,
    });
  }

  useEffect(() => {
    let data = {
      Id: userData?._id
    }
    dispatch(getTickets(data, usertoken));
    dispatch(getProfile(data, usertoken));

  }, []);

  useEffect(() => {
    let allTokens = userData?.assignBrands?.map((i) => i?.token);
    let Data = {
      brand_token: allTokens,
    };
    dispatch(getVisitorAllHistory(Data, usertoken));
  }, []);



  useEffect(() => {
    if (!Socket) return;
    Socket.emit("agentConnect", {
      agentBrands: userData?.assignBrands,
      agentID: userData?._id,
      role: userData?.role,
      displayName: userData?.sudoName,
      name: userData?.name,
      status: IsActive
    });
    Socket?.on("VisitorArrive", async (data) => {
      setnewVisitor(data);
      if (allActive?.length > 0) {
        if (allActive?.some(i => i != data?.unique_id)) {
          setallActive((pre) => [...pre, data?.unique_id])
        }
      }
      else {
        setallActive([data?.unique_id]);
      }
    });
    Socket?.on("AllVisitorArrive", (data) => {
      setVisitorData(data)
    });
    Socket?.on("VisitorBlocked", (data) => {
      // let FindVisitor = VisitorData?.filter((i) => i?.unique_id !== data?.unique_id);
      // setVisitorData(FindVisitor);
      setblockeddata(data);

    });

    Socket?.on("chat_Invite", (data) => {
      setInviteData(data)
    });

  }, [Socket]);
  useEffect(() => {
    if (blockeddata !== null) {
      let FindVisitor = VisitorData?.filter((i) => i?.unique_id !== blockeddata?.unique_id);
      setVisitorData(FindVisitor);
    }
  }, [blockeddata]);

  const onRejct = (x) => {
    let findall = recivedTrasferdata?.filter((i) => i?.agentData?.name != x?.name
      && i?.visitorData?.unique_id != x?.visitorData?.unique_id);
    setrecivedTrasferdata(findall);
    Socket.emit("transferRejected", x);
    notification.destroy();
  }
  const onAccept = (x) => {
    let alldata = {
      agentData: {
        id: userData?._id,
        name: userData?.name
      },
      data: x
    }
    Socket.emit("transferAccepted", alldata);
    notification.destroy();
  }

  const playAudio = () => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    if (audioContext && !userData?.sounds?.incomingVisitor?.isMuted) {
      const audio = new Audio(visitorSounds?.[userData?.sounds?.incomingVisitor?.key]?.sound);
      audio.volume = userData?.sounds?.incomingVisitor?.volume / 100;
      // const audio = new Audio(bell);
      audio.play()
        .catch(error => {
          console.error('Error playing audio:', error);
        });
    }
  };

  /// Sound when visitorArrives
  useEffect(() => {
    if (!Socket) return;
    Socket.on("alert", (data) => {
      playAudio();
    });

  }, [Socket]);
  useEffect(() => {
    let context = new AudioContext();
    context.resume().then((i) => {
    }).catch((e) => { })
  },);
  //// end

  const LogoutUser = () => {
    dispatch({ type: actionTypes.USER_LOGOUT });
    localStorage.removeItem("reduxPersist:root");
    sessionStorage.removeItem("reduxPersist:root");
    navigate("/");
  };

  useEffect(() => {
    if (newVisitor !== null) {
      const ifaleadyHave = VisitorData?.findIndex(
        (i) => i?.unique_id === newVisitor?.unique_id
      )
      if (ifaleadyHave === -1) {
        setVisitorData((oldVisitor) => [newVisitor, ...oldVisitor]);
        setnewVisitor(null);
      } else {
        let newData = VisitorData;
        newData[ifaleadyHave] = newVisitor
        setVisitorData(newData);
        // VisitorData.splice(ifaleadyHave, 1);
        // setVisitorData((oldVisitor) => [newVisitor, ...oldVisitor]);
        setnewVisitor(null);
      }
      if (SelectedVisitorData?.unique_id == newVisitor?.unique_id) {
        setSelectedVisitorData(newVisitor);
      }
    }
    joinServerby();
  }, [newVisitor]);

  const joinServerby = () => {
    VisitorData?.map((i) => i?.servedBy?.some((e) => {
      if (e?.id == userData?._id) {
        Socket.emit("agentJoinVisitorRoom", i?.roomId)
      }
    }))
  };

  const onChnageStatus = (id) => {
    setIsActive(id);
    Socket.emit("changeStatus", {
      agentBrands: userData?.assignBrands,
      agentID: userData?._id,
      displayName: userData?.sudoName,
      name: userData?.name,
      status: id
    });
  };

  const { Header } = Layout;

  const items = [
    {
      label: <><strong>Personal Information</strong></>,
      key: "heading",
    },
    {
      label: <>{userData?.sudoName}</>,
      key: "1",
      onClick: () => {
        navigate("/personal");
      },
    },
    {
      label: <><strong>Status</strong></>,
      key: "subheading",
      map: '1'
    },
    {
      label: <>Online</>,
      key: "22",
      onClick: () => onChnageStatus("22"),
    },
    {
      label: <>Away</>,
      key: "23",
      onClick: () => onChnageStatus("23"),
    },
    {
      label: <>Invisible</>,
      key: "24",
      onClick: () => onChnageStatus("24"),
    },
    {
      label: <>Logout</>,
      key: "5",
      onClick: () => {
        LogoutUser();
      },
      icon: <LogoutOutlined />,
      className: 'logoutBtn'

    }
  ];

  const {
    token: { colorBgContainer }
  } = theme.useToken();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <>

      <div style={{ position: 'relative' }}>

        <Header
          className="header"
          style={{
            background: colorBgContainer,
          }}
        >

          <div className="topHeader">
            <Clock />
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
            >
              {/* IsActive */}
              <a onClick={(e) => e.preventDefault()}>
                {/* <Avatar icon={<UserOutlined />} /> */}
                <Space size={24}>
                  <Badge dot className={IsActive === "22" ? "badgeDotGreen" : IsActive === "23" ? "badgeDotYellow" : "badgeDotgray"}>
                    <Avatar icon={<UserOutlined />} />
                  </Badge>
                </Space>
              </a>

            </Dropdown>

          </div>
        </Header>
        {/* <FloatButtons /> */}
      </div>
    </>

  );
};

export default Headers;
