import * as actionTypes from "./ShortcutsTypes";
import { Post, Get } from "../../helpers/apicalls/apicalls";
import {
  message,
} from "antd";

export const Add_Shortcut = (data, token, onSuccess) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SHORTCUT_IS_LOADING });
    Post("/agents/user/add-shortcut", data, token)
      .then(function (response) {
        if (response?.status) {
          dispatch({
            type: actionTypes.SHORTCUT_DATA_SUCCESS,
            payload: response?.data
          });
          onSuccess();
        } else {
          message.error(response.message);
          dispatch({ type: actionTypes.SHORTCUT_DATA_FALED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.SHORTCUT_DATA_FALED });
      });
  };
};

export const Get_Shortcut = (data, token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING_GET_SHORTCUT });
    Post("/agents/user/get-shortcut", data, token)
      .then(function (response) {
        console.log(response,"response")
        if (response?.status) {
          dispatch({
            type: actionTypes.GET_SHORTCUT_DATA_SUCCESS,
            payload: response?.data
          });
        } else {
          dispatch({ type: actionTypes.GET_SHORTCUT_DATA_FALED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.GET_SHORTCUT_DATA_FALED });
      });
  };
};

export const Edit_Shortcut = (data, token, onSuccess) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING_EDIT_SHORTCUT });
    Post("/agents/user/edit-shortcut", data, token)
      .then(function (response) {
        if (response?.status) {
          dispatch({
            type: actionTypes.EDIT_SHORTCUT_DATA_SUCCESS,
            payload: response?.data
          });
          onSuccess(response?.message);
        } else {
          message.error(response.message);
          dispatch({ type: actionTypes.EDIT_SHORTCUT_DATA_FALED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.EDIT_SHORTCUT_DATA_FALED });
      });
  };
};

export const Delete_Shortcut = (data, token, onSuccess) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.IS_LOADING_DELETE_SHORTCUT });
    Post("/agents/user/delete-shortcut", data, token)
      .then(function (response) {
        if (response?.status) {
          dispatch({
            type: actionTypes.DELETE_SHORTCUT_DATA_SUCCESS,
            payload: response?.data
          });
          onSuccess(response?.message);
        } else {
          message.error(response.message);
          dispatch({ type: actionTypes.DELETE_SHORTCUT_DATA_FALED });
        }
      })
      .catch(function (error) {
        dispatch({ type: actionTypes.DELETE_SHORTCUT_DATA_FALED });
      });
  };
};
