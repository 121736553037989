import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Badge, Form, Input, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  Get_Brands_By_Id,
  Get_All_Brands,
  Add_Brand,
  Brand_Status
} from "../../redux/Brands/BrandsActions";

const Brands = () => {
  const userData = useSelector((state) => state.Auth.userData);
  const allData = useSelector((state) => state.Brands.data);
  const isLoading = useSelector((state) => state.Brands.isLoading);
  const isAddLoading = useSelector((state) => state.Brands.isAddLoading);
  const usertoken = useSelector((state) => state.Auth.token);

  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const data = {
      companyId: userData?.companyId
    };
    if (userData?.role === "superadmin") {
      dispatch(Get_All_Brands(usertoken));
    } else {
      dispatch(Get_Brands_By_Id(data, usertoken));
    }

  }, []);

  const onChange = (pagination, filters, sorter, extra) => {
  };

  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const afterSuccess = () => {
    form.resetFields();
    setOpen(false);
  };

  const afterAdded = () => {

    const data = {
      companyId: userData?.companyId
    };

    if (userData?.role === "superadmin") {
      dispatch(Get_All_Brands(usertoken));
    } else {
      dispatch(Get_Brands_By_Id(data, usertoken));
    }

  };

  const onFinish = (values) => {

    const data = {
      brandname: values.brandName,
      admin_id: userData?._id,
      companyId: userData?.companyId
    };

    dispatch(Add_Brand(data, usertoken, afterSuccess, afterAdded));

  };

  const onStatusChange = (data) => {

    const newdata = {
      admin_id: userData?._id,
      brand_id: data?._id,
      isActive: !data?.isActive
    };

    dispatch(Brand_Status(newdata, usertoken, afterAdded));

  };

  const handleCancel = () => {
    setOpen(false);
  };

  const adminsColumns = [
    {
      title: "Sr #",
      dataIndex: "serial",
    },
    {
      title: "Brand Name",
      dataIndex: "brandname",
    },
    {
      title: "Token",
      dataIndex: "token"
    },
    {
      title: "Status",
      dataIndex: "isActive"
    }
  ];

  const columns = [
    {
      title: "Sr #",
      dataIndex: "serial",
    },
    {
      title: "Brand Name",
      dataIndex: "brandname",
    },
    {
      title: "Token",
      dataIndex: "token"
    }
  ];

  const data = [];

  for (let i = 0; i < allData?.length; i++) {
    data.push({
      key: i,
      serial: i + 1,
      brandname: allData[i]?.brandname,
      token: allData[i]?.token,
      isActive: <Switch checked={allData[i]?.isActive} onChange={() => onStatusChange(allData[i])} />
    });
  }

  return (
    <div>
      <div className="topBtns">
        <div>

        </div>
        <div>
          {userData?.role === "superadmin" || userData?.role === "admin" ?
            <Button type="primary" onClick={showModal}>
              Add Brand
            </Button>
            : null
          }
        </div>

        {/* Modal Start */}
        <Modal
          title="Add Brand"
          open={open}
          onCancel={handleCancel}
          footer={null}>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
            autoComplete="off">
            <Form.Item
              name="brandName"
              rules={[
                {
                  required: true,
                  message: "Please input brand name!"
                }
              ]}>
              <Input placeholder="Brand Name" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isAddLoading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        {/* Modal End */}
      </div>
      <Table
        loading={isLoading}
        columns={userData?.role === "superadmin" || userData?.role === "admin" ? adminsColumns : columns}
        dataSource={data}
        onChange={onChange}
      />
    </div>
  );
};

export default Brands;
